import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TextInputWithIcon from "../components/TextInputWithIcon";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LuggageIcon from '@mui/icons-material/Luggage';
import styles from '../styles/pages/book_now.module.css'
import VehiclesHorizontalList from "../components/VehiclesHorizontalList";
import React, { useEffect, useState } from "react";
import CustomLocationsSearch from "../components/CustomLocationsSearch";
import { useCalculateDistance } from "../hooks/useCalculateDistance";
import { CircularProgress } from "@mui/material";
import axios from "axios";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

interface ErrorsTypes{
    flight_number?: string,
    source?: string,
    destination?: string,
    phone_number?: string,
    number_of_people?: string,
    laggage_count?: string,
    scheduled_for?: string,
    amount?: string,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

const stripePromise = loadStripe('pk_test_miRbH1mGNWdpqt1n110iRp0700Am7LxiOT');

const BookNow = () => {
    const [is_loading, setIsLoading] = useState(true);
    const [flight_number, setFlightNumber] = useState(null);
    const [source, setSource] = useState({});
    const [destination, setDestination] = useState({});
    const [phone_number, setPhoneNumber] = useState('');
    const [number_of_people, setNumberOfPeople] = useState(null);
    const [laggage_count, setLaggageCount] = useState(null);
    const [scheduled_for, setScheduledFor] = useState(null);
    const [distance, setDistance] = useState(0);
    const [amount, setAmount] = useState(0);
    const [code, setCode] = useState('');
    const [is_submitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<ErrorsTypes>({});
    const [otp_error, setOtpError] = useState(null);
    const [access_token, setAccessToken] = useState('');

    const [open_otp_dialog, setOpenOtpDialog] = useState(false);
    const [confirming_otp, setConfirmingOTP] = useState(false);

    const [clientSecret, setClientSecret] = useState('');
    const [payment_status, setPaymentStatus] = useState('');

    const handleClose = () => {
        setOpenOtpDialog(false);
    };

    const {
        calculateDistance
    } = useCalculateDistance();

    useEffect(() => {
        if(Object.keys(source).length > 0 && Object.keys(destination).length > 0){
            const distance = calculateDistance(source, destination);
            setDistance(Number(distance));
        }
    }, [source, destination, calculateDistance]);

    const authUserBeforeOrder = () => {  
        let err:any = {};

        if(flight_number === null){
            err.flight_number = 'Please enter the flight number';
        }

        if(Object.keys(source).length === 0){
            err.source = 'Please select your pick up point';
        }

        if(Object.keys(destination).length === 0){
            err.destination = 'Please select where you are going';
        }

        if(phone_number === null){
            err.phone_number = 'Please enter your phone number';
        }

        if(number_of_people === null){
            err.number_of_people = 'Please enter your number of people';
        }

        if(laggage_count === null){
            err.laggage_count = 'Please enter the your laggage count';
        }

        if(scheduled_for === null){
            err.scheduled_for = 'Please enter the pickup date and time';
        }

        if(amount === null){
            err.amount = 'Please choose the car that you want';
        }

        if(Object.keys(err).length > 0){
            setErrors(err);
            return;
        }

        setIsSubmitting(true);

        let phone_num = phone_number.split('+')[1];

        axios.post('/api/generate-client-otp', {phone_number: phone_num}).then(response => {
            if(response.status === 200){
                setIsSubmitting(false);
                setOpenOtpDialog(true);
            }
        }).catch(err => {
            setIsSubmitting(false);
            console.log("An error occured while trying to generate OTP.")
        })
    }

    const confirmingOTPAndPhoneNumberByAuth = () => {
        axios.post('/oauth/token', {
            grant_type: "password",
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: phone_number.split('+')[1],
            password: code,
            "scope": ""
        }).then(response => {
            setConfirmingOTP(false);
            let access_token = response.data?.access_token;
            setAccessToken(access_token);
            paymentIntent();
        }).catch(err => {
            setConfirmingOTP(false);
            setOtpError(err.response?.data?.message);
        })
    }

    const round = (value: number): number => {
        return Math.round(100 * value) / 100;
    };

    const paymentIntent = async() => {
        setPaymentStatus('loading');
        await axios.post('/api/payment-sheet', {amount: round(amount)}).then(response => {
            setPaymentStatus('success');
            setClientSecret(response.data.paymentIntent)
        }).catch(() => {
            setPaymentStatus("error");
        })
    }

    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
    };

    return (
        <div>
            <Navbar />
                <div className={styles.container}>
                        <>
                            <h4>Request Transportation</h4>

                            <TextInputWithIcon 
                                title="Flight Number"
                                type="text"
                                placeholder="Flight Number"
                                Icon={<FlightTakeoffIcon />}
                                setValue={setFlightNumber}
                            />
                            {errors && errors.flight_number && <span style={{color: 'red'}}>{errors.flight_number}</span>}

                            <CustomLocationsSearch
                                setLocation={setSource}
                                Icon={<LocationOnIcon />}
                                placeholder="Source"
                                title="Source"
                                mt={15}
                            />
                            {errors && errors.source && <span style={{color: 'red'}}>{errors.source}</span>}

                            <CustomLocationsSearch
                                setLocation={setDestination}
                                Icon={<LocationOnIcon />}
                                placeholder="Destination"
                                title="Destination"
                                mt={15}
                            />
                            {errors && errors.destination && <span style={{color: 'red'}}>{errors.destination}</span>}

                            <TextInputWithIcon 
                                title="Your phone number"
                                type="text"
                                placeholder="Phone number"
                                Icon={<LocalPhoneIcon />}
                                is_phone_number={true}
                                mt={15}
                                setValue={setPhoneNumber}
                            />
                            {errors && errors.phone_number && <span style={{color: 'red'}}>{errors.phone_number}</span>}

                            <TextInputWithIcon 
                                title="Number of people"
                                type="text"
                                placeholder="Number of people"
                                Icon={<AccountCircleOutlinedIcon />}
                                mt={15}
                                setValue={setNumberOfPeople}
                            />
                            {errors && errors.number_of_people && <span style={{color: 'red'}}>{errors.number_of_people}</span>}

                            <TextInputWithIcon 
                                title="Laggage Count"
                                type="text"
                                placeholder="Laggage Count"
                                Icon={<LuggageIcon />}
                                mt={15}
                                setValue={setLaggageCount}
                            />
                            {errors && errors.laggage_count && <span style={{color: 'red'}}>{errors.laggage_count}</span>}

                            <TextInputWithIcon 
                                title="Scheduled For"
                                type="datetime-local"
                                placeholder="Scheduled For"
                                Icon={<AccessTimeIcon />}
                                mt={15}
                                setValue={setScheduledFor}
                            />
                            {errors && errors.scheduled_for && <span style={{color: 'red'}}>{errors.scheduled_for}</span>}

                            <h4>Select a car type</h4>
                            {errors && errors.amount && <span style={{color: 'red'}}>{errors.amount}</span>}

                            <VehiclesHorizontalList 
                                setIsLoading={setIsLoading}
                                distance={distance}
                                setAmount={setAmount}
                            />

                            {is_loading === false && (
                                <button 
                                    onClick={authUserBeforeOrder} 
                                    className={styles.button}
                                    disabled={is_submitting}
                                    style={{opacity: is_submitting ? 0.5 : 1}}
                                >
                                    {is_submitting ? (
                                        <CircularProgress size={"18px"} />
                                    ) : (
                                        <span>Confirm Order</span>
                                    )}
                                </button>
                            )}
                        </>
                </div>

                <Dialog
                    open={open_otp_dialog}
                    TransitionComponent={Transition}
                    keepMounted
                    // onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth="sm"
                    fullWidth
                >
                    {payment_status === '' && (
                        <>
                            <DialogTitle>{"Enter the OTP to continue"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Please enter the OTP sent to your mobile number in order to continue with the booking.<br />
                                </DialogContentText><br />
                                
                                <label style={{fontWeight: '500'}}>OTP*</label><br />
                                {otp_error && <><span style={{color: 'red', fontWeight: '600', fontSize: 15}}>The provided OTP is incorrect!</span></>}
                                <input 
                                    style={{
                                        width: '98%', 
                                        padding: 5, 
                                        outline: 'none', 
                                        border: '1px solid gray', 
                                        fontSize: 16, 
                                        borderRadius: 7
                                    }} 
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Enter your OTP"
                                    autoFocus={true} 
                                />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>

                            <Button 
                                onClick={confirmingOTPAndPhoneNumberByAuth}
                                disabled={confirming_otp}
                                style={{opacity: confirming_otp ? 0.5 : 1}}
                            >
                                {confirming_otp ? (
                                    <CircularProgress size={"18px"} />
                                ) : (
                                    <span>Continue</span>
                                )}
                            </Button>
                            </DialogActions>
                        </>
                    )}

                    {payment_status === 'success' && (
                        <div style={{padding: 15}}>
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm />
                            </Elements>
                        </div>
                    )}

                    {payment_status === 'loading' && (
                        <div style={{textAlign: 'center'}}>
                            <CircularProgress />
                        </div>
                    )}
                </Dialog>
            <Footer />
        </div>
    )
}

export default BookNow;