import axios from "axios";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import React, { useState } from "react";
import { CircularProgress, Container, Dialog, Grid } from "@mui/material";
import TextInputWithIcon from "../components/TextInputWithIcon";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import moment from "moment";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MyBookings = () => {
    const [phone_number, setPhoneNumber] = useState('');
    const [is_submitting, setIsSubmitting] = useState(false);
    const [open_otp_dialog, setOpenOtpDialog] = useState(false);
    const [phone_number_error, setPhoneNumberError] = useState('');
    const [access_token, setAccessToken] = useState('');
    const [confirming_otp, setConfirmingOTP] = useState(false);
    const [code, setCode] = useState('');
    const [otp_error, setOtpError] = useState(null);
    const [my_orders, setMyOrders] = useState([]);
    const [my_orders_status, setMyOrdersStatus] = useState('');

    const handleClose = () => {
        setOpenOtpDialog(false);
    };

    const generateOtp = () => {
        if(phone_number === ''){
            setPhoneNumberError('Please enter the phone number');
            return;
        }
        setIsSubmitting(true);
        let phone_num = phone_number.split('+')[1];
        axios.post('/api/generate-client-otp', {phone_number: phone_num}).then(response => {
            if(response.status === 200){
                setIsSubmitting(false);
                setOpenOtpDialog(true);
            }
        }).catch(err => {
            setIsSubmitting(false);
            console.log("An error occured while trying to generate OTP.")
        })
    }

    const confirmingOTPAndPhoneNumberByAuth = () => {
        setConfirmingOTP(true);
        axios.post('/oauth/token', {
            grant_type: "password",
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            username: phone_number.split('+')[1],
            password: code,
            "scope": ""
        }).then(response => {
            setConfirmingOTP(false);
            setOpenOtpDialog(false);
            let access_token = response.data?.access_token;
            getClientOrders(access_token);
            setAccessToken(access_token);
        }).catch(err => {
            setConfirmingOTP(false);
            setOtpError(err.response?.data?.message);
        })
    }

    const getClientOrders = async(access_token: string) => {
        setMyOrdersStatus('loading');
        await axios.get('/api/all-client-orders', {
            headers: {
                Authorization: 'Bearer ' + access_token
            }
        }).then(response => {
            setMyOrdersStatus('success');
            setMyOrders(response.data);
        }).catch(() => {
            setMyOrdersStatus('error');
        })
    }

    const statusColor = (status: string) => {
        switch(status){
            case "active":
                return "green";
                break;
            case "in transit":
                return "orange";
                break;
            case "completed":
                return "green";
                break;
            default:
                return "#414141"
        }
    }

    const timeElapsed = (created_at: any) => {
        const currentTime = moment(); // Current time on the client
        const createdAt = moment(created_at); // Post's created at time

        // Calculate the time difference in days, hours, and minutes
        const diffInDays = currentTime.diff(createdAt, 'days');
        const diffInHours = currentTime.diff(createdAt, 'hours') % 24;
        const diffInMinutes = currentTime.diff(createdAt, 'minutes') % 60;

        // Format the time difference into a human-readable string
        let timeElapsedString = '';
        
        if (diffInMinutes > 0) {
            timeElapsedString = ` ${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'}`;
        }
        if (diffInHours > 0) {
            timeElapsedString = ` ${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}`;
        }
        if (diffInDays > 0) {
            timeElapsedString = `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`;
        }

        return timeElapsedString + ' ago';
    }

    return (
        <div>
            <Navbar />
            <div style={{minHeight: '50vh'}}>
                <Container>
                    <div style={{marginTop: 40, display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        <div style={{width: '100%'}}>
                            <TextInputWithIcon 
                                title="Enter Your phone number in order to see your bookings"
                                type="text"
                                placeholder="Phone number"
                                Icon={<LocalPhoneIcon />}
                                is_phone_number={true}
                                mt={15}
                                setValue={setPhoneNumber}
                            />
                        </div>
                        
                        <button 
                            style={{
                                height: 43, 
                                marginLeft: 10, 
                                marginTop: 20,
                                backgroundColor: 'red', 
                                border: 'none', 
                                minWidth: 100, 
                                fontSize: 16, 
                                fontWeight: '600', 
                                color: 'white', 
                                borderRadius: 5, 
                                cursor: 'pointer'
                            }}
                            onClick={generateOtp}
                        >
                            Submit
                        </button>
                    </div>
                    {phone_number_error !== '' && <span style={{color: 'red'}}>{phone_number_error}</span>}

                    <div style={{textAlign: 'center'}}>
                        {my_orders_status === 'loading' && (
                            <CircularProgress />
                        )}

                        {my_orders_status === 'success' && (
                            <h4>Your Orders for the past 30 days</h4>
                        )}

                        {my_orders_status === 'success' && my_orders.map((order: any, index) => (
                            <div 
                                key={index} 
                                style={{
                                    textAlign: 'left', 
                                    margin: 10, 
                                    padding: 20, 
                                    border: '1px solid gray',
                                    borderRadius: 10
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Order Number:</b> {order.attributes.order_number}</p> 
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Source:</b> {order.attributes.source}</p>
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Destination:</b> {order.attributes.destination}</p>
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Amount:</b> {order.attributes.currency} {order.attributes.amount}</p>
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Number of People:</b> {order.attributes.number_of_people}</p>
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Laggage Count:</b> {order.attributes.laggage_count}</p>
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Scheduled Date:</b> {order.attributes.scheduled_date}</p>
                                    </Grid>

                                    <Grid md={4}>
                                        <p style={{color: '#414141'}}><b>Status:</b> <span style={{color: statusColor(order.attributes.status), fontWeight: '500'}}>{order.attributes.status}</span></p>
                                    </Grid>
                                </Grid>
                                
                                {timeElapsed(order.attributes.created_at)}
                            </div>
                        ))}
                    </div>
                </Container>
            </div>

            <Dialog
                    open={open_otp_dialog}
                    TransitionComponent={Transition}
                    keepMounted
                    // onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth="sm"
                    fullWidth
                >
                        <>
                            <DialogTitle>{"Enter the OTP to continue"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Please enter the OTP sent to your mobile number in order to continue with the booking.<br />
                                </DialogContentText><br />
                                
                                <label style={{fontWeight: '500'}}>OTP*</label><br />
                                {otp_error && <><span style={{color: 'red', fontWeight: '600', fontSize: 15}}>The provided OTP is incorrect!</span></>}
                                <input 
                                    style={{
                                        width: '98%', 
                                        padding: 5, 
                                        outline: 'none', 
                                        border: '1px solid gray', 
                                        fontSize: 16, 
                                        borderRadius: 7
                                    }} 
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Enter your OTP"
                                    autoFocus={true} 
                                />
                            </DialogContent>
                            <DialogActions>
                            
                            {confirming_otp === false && (
                                <Button onClick={handleClose}>Cancel</Button>
                            )}

                            <Button 
                                onClick={confirmingOTPAndPhoneNumberByAuth}
                                disabled={confirming_otp}
                                style={{opacity: confirming_otp ? 0.5 : 1}}
                            >
                                {confirming_otp ? (
                                    <CircularProgress size={"18px"} />
                                ) : (
                                    <span>Continue</span>
                                )}
                            </Button>
                            </DialogActions>
                        </>
                </Dialog>
            <Footer />
        </div>
    )
}
export default MyBookings;