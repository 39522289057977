import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import axios from "axios";
import styles from '../styles/pages/Vehicles.module.css';
import { CircularProgress } from "@mui/material";
import unbrandedCar from '../images/unbranded_car.jpg';

const OurVehicles = () => {

    const [vehicle_types, setVehicleTypes] = useState<any[]>([]);
    const [status, setStatus] = useState('');

    useEffect(() => {
        const carTypes = async() => {
            setStatus('loading');
            await axios.get('/api/car-types').then(response => {
                setVehicleTypes(response.data);
                setStatus('success');
            }).catch(() => {
                setStatus('error');
            })
        }

        carTypes();
    }, []);

    console.log(vehicle_types)


    return (
        <div>
            <Navbar />
            <div className={styles.vehicles_banner}>
                <h1 style={{color: 'white', textAlign: 'center', fontWeight: '500'}}>Our Vehicles</h1>
            </div>

            <div style={{minHeight: '60vh', padding: 15}}>
                <div className="container">
                    <p>Demars Ontime vehicles excel in providing a superior transportation experience. Combining comfort, cleanliness, and efficiency, these vehicles offer passengers a delightful and environmentally conscious way to travel. With a commitment to passenger well-being and a focus on sustainability, Demars Ontime vehicles are setting new standards for modern transportation.</p>

                    <h4>You can choose from the following vehicles.</h4>

                    {status === 'success' && vehicle_types.length > 0 && vehicle_types.map((vehicle, index) => (
                        <div key={index} className={styles.vehicle_type}>
                            <img src={unbrandedCar} style={{width: 150}} alt="" />
                            
                            <div>
                                <h4 style={{fontWeight: '500'}}>{vehicle.attributes.name}</h4>
                                <p style={{marginTop: 15}}>Price Per Mile: ${vehicle.attributes.price_per_mile}</p>
                            </div>
                        </div>
                    ))}

                    {status === 'error' && (
                        <div>
                            <p style={{textAlign: 'center', color: 'red', marginTop: 20}}>We encountered an error while trying to render the page. Please reload!</p>
                        </div>
                    )}

                    {status === 'loading' && (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress style={{textAlign: 'center'}} /></div>)}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OurVehicles;