import { Alert, CircularProgress, Snackbar } from '@mui/material';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useState } from 'react';
import poweredByStripe from '../images/powered_by_stripe.png';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [checking_out, setCheckingOut] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setCheckingOut(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://demarsontime.vercel.app",
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
      setCheckingOut(false);
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button 
        disabled={checking_out}
        style={{
          width: '100%',
          padding: 10,
          marginTop: 15,
          backgroundColor: '#d02629',
          fontSize: 16,
          color: 'white',
          fontWeight: '500',
          borderRadius: 10,
          border: 'none',
          cursor: 'pointer',
          opacity: checking_out ? 0.5 : 1
        }}
      >
        {checking_out ? (
          <CircularProgress size={"18px"} />
        ) : (
          <span>Checkout</span>
        )}
      </button>

      <img src={poweredByStripe} alt="" style={{width: 200, float: 'right', marginTop: 10}} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error ? true : false}
        message={error}
        key={'top right'}
        autoHideDuration={3000}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </form>
  )
};

export default CheckoutForm;