import axios from "axios";
import { useEffect, useState } from "react";
import styles from '../styles/pages/book_now.module.css'
import { CircularProgress } from "@mui/material";
import carIcon from '../images/sedan_icon.png';

interface VehicleListProps{
    setIsLoading: Function,
    distance: number,
    setAmount: Function
}

const VehiclesHorizontalList = ({setIsLoading, distance, setAmount}: VehicleListProps) => {
    const [vehicle_types, setVehicleTypes] = useState<any[]>([]);
    const [status, setStatus] = useState('');
    const [amount_per_mile, setAmountPerMile] = useState(0);
    const [selected_car, setSelectedCar] = useState(null);

    useEffect(() => {
        const carTypes = async() => {
            setStatus('loading');
            await axios.get('/api/car-types').then(response => {
                setVehicleTypes(response.data);
                setStatus('success');
                setIsLoading(false);
            }).catch(() => {
                setStatus('error');
                setIsLoading(false);
            })
        }

        carTypes();
    }, [setIsLoading]);

    const carSelected = (amount_per_mile: number) => {
        let amount: number;

        setAmountPerMile(amount_per_mile);

        if(distance > 0){
            amount = (Number(amount_per_mile) * Number(distance));
        }else{
            amount = amount_per_mile;
        }

        setAmount(amount.toFixed(2));
    }

    useEffect(() => {
        if(amount_per_mile){
            setAmount(Number(amount_per_mile) * Number(distance));
        }
    }, [distance, amount_per_mile, setAmount])

    return (
        <div className={styles.car_buttons_container}>
            {status === 'success' && vehicle_types.map((vehicle, index) => (
                <button 
                    key={index} style={{minWidth: 160}}
                    onClick={() => {
                        setSelectedCar(vehicle.id);
                        carSelected(Number(vehicle.attributes.price_per_mile))
                    }}
                    className={selected_car === vehicle.id ? styles.selected_car: ''}
                >
                    <img src={carIcon} alt="" style={{width: 40}} />

                    <span style={{textAlign: 'left', marginLeft: 10}}>
                        {vehicle.attributes.name}<br />
                        {distance === 0 ? (
                            <b>From ${vehicle.attributes.price_per_mile}</b>
                        ) : (
                            <b>${(Number(vehicle.attributes.price_per_mile) * distance).toFixed(2)}</b>
                        )}
                    </span>
                </button>
            ))}

            {status === 'loading' && (
                <div style={{textAlign: 'center'}}>
                    <CircularProgress />
                </div>
            )}

            {status === 'error' && (
                <div style={{textAlign: 'center'}}>
                    <p>An error occured while trying to load...</p>
                </div>
            )}
        </div>
    )
}
export default VehiclesHorizontalList;