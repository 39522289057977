import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import styles from '../styles/components/navbar.module.css';
import logo from '../images/logo.png';
import mobileLogo from '../images/mobile_logo.jpg';
import { Drawer } from '@mui/material';
import { useState } from 'react';

const Navbar = () => {

    const [anchor_open, setAnchorOpen] = useState(false);
    const [mobile_anchor_open, setMobileAnchorOpen] = useState(false);

    return (
        <div className={styles.container} style={{position: 'relative'}}>
            <div className={styles.desktop_nav}>
                <div className={styles.top}>
                    <div className='container'>
                        <div className={styles.row_flex}>
                            <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                                <span style={{color: 'gray'}}>Reliable Taxi Service & Transport Solutions!</span>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'row', borderLeft: '1px solid gray', borderRight: '1px solid gray', padding: 10}}>
                                    <Link to="#" style={{marginLeft: 15}}>Help</Link>
                                    <Link to="#" style={{marginLeft: 15}}>Support</Link>
                                    <Link to="#" style={{marginLeft: 15, marginRight: 15}}>FAQ</Link>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <FacebookIcon style={{color: 'gray', marginLeft: 15}} />
                                    <TwitterIcon style={{color: 'gray', marginLeft: 15}} />
                                    <InstagramIcon style={{color: 'gray', marginLeft: 15}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.logo_and_contact}>
                    <div className='container'>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                                <Link to="/">
                                    <img src={logo} style={{width: 130}} alt="" />
                                </Link>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <a href="tel:+18336669400" style={{display: 'flex', flexDirection: 'row'}}>
                                    <PhoneInTalkOutlinedIcon style={{fontSize: 40, color: '#d02629'}} />
                                    <div style={{marginLeft: 15}}>
                                        <p style={{padding: 0, margin: 0, color: '#414141'}}>Call Us Now</p>
                                        <p style={{padding: 0, margin: 0, color: '#414141', fontSize: 18, fontWeight: '600'}}>+1 833-666-9400</p>
                                    </div>
                                </a>

                                <a href="mailto:info@demarsontime.com" style={{display: 'flex', flexDirection: 'row'}}>
                                    <DraftsOutlinedIcon style={{fontSize: 40, color: '#d02629', marginLeft: 20}} />
                                    <div style={{marginLeft: 15}}>
                                        <p style={{padding: 0, margin: 0, color: '#414141'}}>Email</p>
                                        <p style={{padding: 0, margin: 0, color: '#414141', fontSize: 18, fontWeight: '600'}}>info@demarsontime.com</p>
                                    </div>
                                </a>

                                <a href="https://goo.gl/maps/qtY6pL2ymfzXVFS88" target='_blank' rel="noreferrer" style={{display: 'flex', flexDirection: 'row'}}>
                                    <MapOutlinedIcon style={{fontSize: 40, color: '#d02629', marginLeft: 20}} />
                                    <div style={{marginLeft: 15}}>
                                        <p style={{padding: 0, margin: 0, color: '#414141'}}>Address</p>
                                        <p style={{padding: 0, margin: 0, color: '#414141', fontSize: 18, fontWeight: '600'}}>Marco Island, FL 34145, USA</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.navbar}>
                    <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                        <Link to="/" style={{color: 'white', fontWeight: '600', marginLeft: 25}}>Home</Link>
                        <Link to="/about-us" style={{color: 'white', fontWeight: '600', marginLeft: 25}}>About us</Link>
                        <Link to="/our-vehicles" style={{color: 'white', fontWeight: '600', marginLeft: 25}}>Our Vehicles</Link>
                        <Link to="/contact-us" style={{color: 'white', fontWeight: '600', marginLeft: 25}}>Contact us</Link>
                    </div>

                    <Link to="#" onClick={() => setAnchorOpen(true)} style={{padding: 10}}>
                        <MenuOutlinedIcon style={{color: 'white', fontSize: 30}} />
                    </Link>

                    <Link to="/book-now" className={styles.book_now_btn}>Book Transportation</Link>
                </div>

                <Drawer
                    anchor='right'
                    open={anchor_open}
                    onClose={() => setAnchorOpen(!anchor_open)}
                    style={{padding: 20}}
                >
                    <div style={{width: 400, padding: 25}}>
                        <img src={logo} style={{width: 130}} alt="" />
                        <p style={{color: '#414141', fontSize: 16}}>Everything needed for your transportation business to grow exponentially is here. Here at Demars Ontime we give an opportunity for drivers and clients to connect.</p><br />

                        <p style={{margin: 0, padding: 0}}>Call us on:</p>
                        <p style={{padding: 0, margin: 0, color: '#d02629', fontSize: 22, fontWeight: '600'}}>+1 833-666-9400 </p>

                        <p style={{margin: 0, padding: 0, marginTop: 30}}>You can find us at:</p>
                        <p style={{padding: 0, margin: 0, color: '#414141', fontSize: 18, fontWeight: '600'}}>Marco Island, FL 34145, USA</p>

                        <p style={{margin: 0, padding: 0, marginTop: 30}}>Email us now:</p>
                        <p style={{padding: 0, margin: 0, color: '#414141', fontSize: 18, fontWeight: '600'}}>info@demarsontime.com</p>
                    </div>
                </Drawer>
            </div>

            <div className={styles.mobile_nav}>
                <div className={styles.logo_and_contact}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                            <Link to="/">
                                <img src={mobileLogo} style={{width: 100}} alt="" />
                            </Link>
                        </div>

                        <Link to="#" onClick={() => setMobileAnchorOpen(true)} style={{padding: 10}}>
                            <MenuOutlinedIcon style={{color: 'black', fontSize: 25}} />
                        </Link>
                    </div>
                </div>
                
                <Drawer
                    anchor='right'
                    open={mobile_anchor_open}
                    onClose={() => setMobileAnchorOpen(!mobile_anchor_open)}
                    style={{padding: 20}}
                >
                    <div style={{width: 250}}>
                        <h2 style={{margin: 10, marginTop: 20, fontWeight: '500', fontSize: 20}}>Menu</h2>
                        <Link to="/">
                            <div className={styles.mobile_menu_list}>
                                Home
                            </div>
                        </Link>

                        <Link to="/about-us">
                            <div className={styles.mobile_menu_list}>
                                About us
                            </div>
                        </Link>
                        
                        <Link to="/our-vehicles">
                            <div className={styles.mobile_menu_list}>
                                Our Vehicles
                            </div>
                        </Link>

                        <Link to="/contact-us">
                            <div className={styles.mobile_menu_list}>
                                Contact us
                            </div>
                        </Link>
                    </div>
                </Drawer>
            </div>
        </div>
    )
}
export default Navbar;