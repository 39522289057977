import { Grid } from '@mui/material';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import styles from '../styles/components/footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className="container">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <p style={{color: 'white'}}>We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new technologies.</p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <div className={styles.right_phone_number}>
                            <div className={styles.colored_phone_number_container}>
                                <div className={styles.straight_skew} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <DraftsOutlinedIcon style={{color: 'white', fontSize: 50, marginLeft: -20}} />
                                    <div style={{marginLeft: 20}}>
                                        <p style={{color: 'white'}}>For inquiry:</p>
                                        <h3 style={{color: 'black', marginTop: -10}}>info@demarsontime.com</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <div className={styles.all_links_container}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <h4 style={{color: 'white'}}>Useful Links</h4>
                                    <div className={styles.thick_divider}></div>

                                    <div className={styles.link_container}>
                                        <Link to="">Home</Link>
                                    </div>
                                    <div className={styles.link_container}>
                                        <Link to="/about-us">About Us</Link>
                                    </div>
                                    <div className={styles.link_container}>
                                        <Link to="our-vehicles">Our Vehicles</Link>
                                    </div>
                                    <div className={styles.link_container}>
                                        <Link to="contact-us">Contact Us</Link>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4}>
                                    <h4 style={{color: 'white'}}>Head Office</h4>
                                    <div className={styles.thick_divider}></div>

                                    <div className={styles.link_container}>
                                        <h4 style={{color: '#d02629'}}>LOCATION</h4>
                                        <p style={{color: 'white', marginTop: -10}}>Marco Island, FL 34145, USA</p>
                                    </div>

                                    <div className={styles.link_container}>
                                        <h4 style={{color: '#d02629'}}>CALL US</h4>
                                        <p style={{color: 'white', marginTop: -10}}>+1 833-666-9400</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4}>
                                    <h4 style={{color: 'white'}}>Working Hours</h4>
                                    <div className={styles.thick_divider}></div>

                                    <div className={styles.link_container}>
                                        <h4 style={{color: '#d02629'}}>MONDAY - FRIDAY:</h4>
                                        <p style={{color: 'white', marginTop: -10}}>9.00am To 8.00pm</p>
                                    </div>

                                    <div className={styles.link_container}>
                                        <h4 style={{color: '#d02629'}}>SATURDAY - SUNDAY:</h4>
                                        <p style={{color: 'white', marginTop: -10}}>10.00am To 7.30pm</p>
                                    </div>
                                    
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className={styles.copyright}>
                <p style={{color: 'white', textAlign: 'center'}}>Copyright &copy; 2018 Demar's Ontime Transpotation LLC.</p>
            </div>
        </div>
    )
}

export default Footer;