import * as geolib from 'geolib';

const useCalculateDistance = () => {

    const calculateDistance = (source_coordinates, destination_coordinates) => {

        if(source_coordinates && destination_coordinates){
    
            const dist = geolib.getDistance(source_coordinates, destination_coordinates, 10); // Calculate the distance between coords1 and coords2 in meters with a margin of error of 10 meters
        
            const totalDistanceInMeters = dist;

            const distance_in_miles = geolib.convertDistance(totalDistanceInMeters, 'mi');
        
            return distance_in_miles;
        }else{
            return null;
        }
    };

    return {calculateDistance};
}

export {useCalculateDistance};