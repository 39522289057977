import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

interface Props {
    title: string,
    text: string,
    image: string
}

export default function CustomCards({title, text, image} : Props) {
  const [screen_width, setScreenWidth] = React.useState(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Use the useEffect hook to add and remove a window resize event listener
  React.useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('resize', updateScreenWidth);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  return (
    <Card sx={{ width: '100%', padding: 0.2 }}>
      <CardMedia
        sx={{ height: screen_width <= 767 ? 220 : 300 }}
        image={image}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {title}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {text}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}