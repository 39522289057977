import Autocomplete from "react-google-autocomplete";

interface locationProps{
    setLocation: Function,
    Icon?: JSX.Element,
    placeholder: string,
    title: string,
    mt?: number
}

const CustomLocationsSearch = ({setLocation, Icon, placeholder, title, mt} : locationProps) => {
    return (
        <div style={{marginTop: mt ?? 0}}>
            <label style={{fontWeight: '500', color: '#414141'}}>{title}</label>
            <div style={{display:'flex', flexDirection: 'row'}}>
                <div
                    style={{
                        borderTop: '1px solid rgb(197, 197, 197)',
                        borderBottom: '1px solid rgb(197, 197, 197)',
                        borderLeft: '1px solid rgb(197, 197, 197)',
                        padding: '2px',
                        borderTopLeftRadius: 7,
                        borderBottomLeftRadius: 7,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {Icon}
                </div>

                <div 
                    style={{
                        borderTop: '1px solid rgb(197, 197, 197)',
                        borderBottom: '1px solid rgb(197, 197, 197)',
                        borderRight: '1px solid rgb(197, 197, 197)',
                        borderTopRightRadius: 7,
                        borderBottomRightRadius: 7,
                        padding: '2px',
                        display: 'flex', 
                        flexDirection: 'row',
                        flex: 1
                    }}
                >
                    <Autocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        onPlaceSelected={(place) => {
                            setLocation({name: place.formatted_address, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng()})
                        }}
                        options={{
                            componentRestrictions: { country: "us" },
                        }}
                        style={{
                            fontSize: '16px',
                            height: '30px',
                            outline: 'none',
                            border: 'none',
                            padding: '5px',
                            paddingLeft: 0,
                            width: '100%'
                        }}
                        placeholder={placeholder}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomLocationsSearch;