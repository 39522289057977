import { FormEvent, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styles from '../styles/pages/Contact.module.css';
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";

const ContactUs = () => {

    const [full_name, setFullName] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [open_snackbar, setOpenSnackbar] = useState(false);
    const [is_error, setIsError] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const sendMessage = (event: FormEvent) : void => {
        event.preventDefault();

        setSubmitting(true);

        axios.post('/api/email-owner?api_key=gskdjfod78i2rnfjkdf7yiw230urnfkdf', {full_name, subject, email, message}).then(response => {
            if(response.status === 200){
                setOpenSnackbar(true);
                setSubmitting(false);
                setIsError(false);

                setFullName('');
                setSubject('');
                setEmail('');
                setMessage('');
                
                setTimeout(() => {
                    setOpenSnackbar(false);
                }, 3000);
            }
        }).catch(err => {
            setIsError(true);

            setSubmitting(false)

            setOpenSnackbar(true);
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 3000);
        })
    }
    
    return (
        <div>
            <Navbar />
            <div style={{minHeight: '60vh'}}>
                <div className={styles.contact_banner}>
                    <h1 style={{color: 'white', textAlign: 'center', fontWeight: '500'}}>Contact Us</h1>
                </div>

                <form className={styles.form} onSubmit={sendMessage}>
                    <input 
                        type="text" 
                        placeholder="Full Name" 
                        value={full_name}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                    /><br /><br />

                    <input 
                        type="text" 
                        placeholder="Subject" 
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    /><br /><br />

                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    /><br /><br />

                    <textarea 
                        rows={5}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea><br /><br />

                    <button style={{opacity: submitting ? 0.5 : 1}} disabled={submitting}>
                        {submitting ? 'Submitting...' : 'Send Message'}
                    </button>
                </form>

                <Snackbar open={open_snackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                    <Alert onClose={() => setOpenSnackbar(false)} severity={is_error ? "error" : "success"} sx={{ width: '100%' }}>
                        {is_error ? 'Error while trying to send email.' : 'We have received your email.'}
                    </Alert>
                </Snackbar>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs;