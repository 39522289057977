import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import styles from '../styles/pages/Home.module.css';
import { Grid, Paper } from "@mui/material";
import inBenz from '../images/inbenz.webp';
import Suv from '../images/suv.jpg';
import clientCar from '../images/client_mobile.jpg';
import chiBus from '../images/chi_bus.webp';
import CustomCards from "../components/CustomCards";
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Footer from "../components/Footer";
import playStore from '../images/playstore.png';
import appStore from '../images/appstore.png';

const Home = () => {
    return (
        <div>
            <Navbar />

            <div className={styles.banner_top}>
                <div className="container">
                    <p style={{color: 'white', fontWeight: '600'}}>Travel Securely With Us!</p>
                    <h1 style={{color: 'white', fontSize: 40, fontWeight: '500'}}>
                        Book a vehicle from anywhere<br />
                        Within Marco and Naples!
                    </h1>
                    <p style={{color: 'white', fontWeight: '400'}}>
                        Everything your transportation business needs is already here!<br />
                        Demars ontime is made just for you!
                    </p><br /><br />
                    
                    <Link to="/book-now" className={styles.book_now_btn} style={{borderRadius: 15}}>Book Now</Link>

                    <Link to="/my-bookings" className={styles.book_now_btn} style={{marginLeft: 20, borderRadius: 15, backgroundColor: 'white', color: 'red'}}>My Bookings</Link>
                </div>

                <div className={styles.left_bottom_corner_white}></div>
            </div>

            <div className={styles.about_us}>
                <div className="container">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <img src={inBenz} style={{width: '100%'}} alt="" />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <div className={styles.about_words}>
                                <h1 style={{color: '#d02629'}}>{'///'} Who we are</h1>
                                <p style={{fontSize: 18}}>
                                    Demar's Ontime Transport is a reliable and luxury transportation company that provides travel services to those based in and around Marco Island and Naples, Florida.<br /><br />
                                    
                                    We value our customers as they are the lifeblood of our business, and not only are we dedicated to providing a luxury service to all, we believe it is our duty to uphold great customer service.<br /><br />

                                    We are devoted to providing you with an unforgettable chauffeur service ever and with that, are available for you 24/hrs, 7 days per week and even on holidays. <br /><br />
                                    
                                    Our drivers are very respectful, courteous, professional, and have combined professional driving experience of 10+ years.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            
            <div className={styles.our_services}>
                <div className="container">
                    <h1 style={{color: '#d02629', textAlign: 'center'}}>{'///'} Our Services</h1>
                    <h1 style={{textAlign: 'center', color: 'white', fontWeight: '500', fontSize: 30}}>Start your transportations with Demars Ontime</h1>
                    <p style={{color: 'white', fontSize: 18, textAlign: 'center', fontWeight: '500'}}>We offer transportation services to people within Marco and Naples. Download<br /> our App and access all our services.</p>

                    <div className={styles.cards_container}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                                <CustomCards 
                                    title="Luxury Transportation"
                                    text="Demar's Ontime Transport provides luxury transportation services to customers in and around Marco Island and Naples, Florida. They offer high-end vehicles and a premium travel experience."
                                    image={Suv}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <CustomCards 
                                    title="Chauffeur Service"
                                    text="Demar's Ontime Transport  is one of the best Private Chauffeur / Limousine Service in the Naples / Marco Island  area. Our dedicated team works day and night to guarantee you, our customer, receive only the best service possible."
                                    image={inBenz}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                                <CustomCards 
                                    title="Chartered Service"
                                    text="You can choose either SUV, Luxury sedan, or a full-size Sprinter van to meet your transportation needs. No matter the occasion, school activity, wedding, corporate group or private party  Demar's Ontime Transport are available for you. "
                                    image={chiBus}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

            <div className={styles.testimonial}>
                <div className="container">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className={styles.testimonial_content}>
                                <h3 style={{color: '#414141'}}>{'///'} CLIENT'S TESTIMONIAL</h3>
                                <h1 style={{color: '#414141 '}}>Our Client's Reviews...</h1>
                                <p>Our clients have the following to say about our services.</p>

                                <Carousel 
                                    showThumbs={false} 
                                    showStatus={false}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    interval={5000}
                                >
                                    <Paper className={styles.carousel_legend}>
                                        <div className={styles.quotation_container}>
                                            <FormatQuoteIcon style={{fontSize: 25, color: 'white'}} />
                                        </div>
                                        <p style={{fontSize: 18, fontWeight: '500', fontStyle: 'italic', marginTop: 30, marginBottom: 30}}>Punctual, courteous, cleanliness, economical, navigation and everything you think driving should include, it's  at Demars Ontime Transport. LOVE IT!!</p>

                                        <h3>~ Horace Bennett</h3>
                                    </Paper>

                                    <Paper className={styles.carousel_legend}>
                                        <div className={styles.quotation_container}>
                                            <FormatQuoteIcon style={{fontSize: 25, color: 'white'}} />
                                        </div>
                                        <p style={{fontSize: 18, fontWeight: '500', fontStyle: 'italic', marginTop: 30, marginBottom: 30}}>Best transportation on Marco Island. Demar and his fleet of extremely well maintained, and high class vehicles, will have you arriving on-time, and in style. Rates are more than reasonable for the level of service you get.</p>

                                        <h3>~ Marti Greif</h3>
                                    </Paper>

                                    <Paper className={styles.carousel_legend}>
                                        <div className={styles.quotation_container}>
                                            <FormatQuoteIcon style={{fontSize: 25, color: 'white'}} />
                                        </div>
                                        <p style={{fontSize: 18, fontWeight: '500', fontStyle: 'italic', marginTop: 30, marginBottom: 30}}>Wonderful fleet of well maintained vehicles, reasonable rates. We traveled around Marco Island as well as to the airport. Kids loved the DVD player.  A gem of a car service.</p>

                                        <h3>~ Bernice Wahler</h3>
                                    </Paper>
                                </Carousel>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <div className={styles.why_us}>
                                <h3 style={{color: 'white'}}>{'///'} WHY CHOOSE US!</h3>
                                <h1 style={{color: 'white'}}>Why Ride With Demar's Ontime?</h1>
                                <p style={{color:'white', fontSize: 18}}>We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master technologies.</p>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <DiamondOutlinedIcon style={{fontSize: 50, color: 'white'}} />
                                    <div style={{marginLeft: 20}}>
                                        <h3 style={{color: 'white'}}>Safe Guarantee</h3>
                                        <p style={{color: 'white', fontSize: 18, marginTop: -10}}>We successfully cope with tasks of varying complexity!</p>
                                    </div>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                                    <DirectionsCarFilledOutlinedIcon style={{fontSize: 50, color: 'white'}} />
                                    <div style={{marginLeft: 20}}>
                                        <h3 style={{color: 'white'}}>Fast Pickups</h3>
                                        <p style={{color: 'white', fontSize: 18, marginTop: -10}}>We successfully cope with tasks of varying complexity!</p>
                                    </div>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                                    <QueryBuilderOutlinedIcon style={{fontSize: 50, color: 'white'}} />
                                    <div style={{marginLeft: 20}}>
                                        <h3 style={{color: 'white'}}>Quick Ride</h3>
                                        <p style={{color: 'white', fontSize: 18, marginTop: -10}}>We successfully cope with tasks of varying complexity!</p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className={styles.app_download}>
                <div className="container">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={5}>
                            <div style={{padding: 15}}>
                            <h2 style={{color: '#d02629'}}>{'///'} Online Booking</h2>
                            <h1 style={{fontWeight: '500'}}>Get Demar's Ontime Apps and start your journey today!</h1>
                            <p>Your Ultimate Ride Connection! Seamlessly connect passengers with reliable drivers for prompt, comfortable rides. </p>

                            <p style={{fontWeight: '600', marginTop: 30}}>Download our apps on both android and ios!</p>

                            <div>
                                <h4 style={{color: '#d02629'}}>Are you a client/passenger, download now!</h4>
                                <Grid container spacing={2} style={{marginTop: -20}}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <a href="https://apps.apple.com/zm/app/demars-ontime/id6448045448" target="_blank" rel="noreferrer">
                                            <img src={appStore} style={{width: '100%'}} alt="" />
                                        </a>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <a href="https://play.google.com/store/apps/details?id=com.dot_app" target="_blank" rel="noreferrer">
                                            <img src={playStore} style={{width: '100%'}} alt="" />
                                        </a>
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <h4 style={{color: '#d02629'}}>Are you a driver, Download now!</h4>
                                <Grid container spacing={2} style={{marginTop: -20}}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <a href="https://apps.apple.com/zm/app/demars-ontime-driver/id6448044927" target="_blank" rel="noreferrer">
                                            <img src={appStore} style={{width: '100%'}} alt="" />
                                        </a>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <a href="https://play.google.com/store/apps/details?id=com.dot_app_driver" target="_blank" rel="noreferrer">
                                            <img src={playStore} style={{width: '100%'}} alt="" />
                                        </a>
                                    </Grid>
                                </Grid>
                            </div>
                            </div>
                        </Grid>
            
                        <Grid item xs={12} sm={12} md={7}>
                            <div className={styles.client_car_image_container} style={{marginTop: 100}}>
                                <img src={clientCar} style={{width: '100%'}} alt="" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Home;