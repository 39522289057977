import { Grid } from "@mui/material";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import styles from '../styles/pages/About.module.css';
import inBenz from '../images/inbenz.webp';

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <div style={{minHeight: '50vh'}}>
                <div className={styles.about_us}>
                    <div className="container">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <img src={inBenz} style={{width: '100%'}} alt="" />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <div className={styles.about_words}>
                                    <h1 style={{color: '#d02629'}}>{'///'} About us</h1>
                                    <p style={{fontSize: 18}}>
                                        Demar's Ontime Transport is not just your ordinary transportation company. We are a reliable and luxurious travel service provider catering to the needs of individuals in and around Marco Island and Naples, Florida. With a strong commitment to excellence, we strive to exceed the expectations of our valued customers.<br /><br />

                                        At Demar's Ontime Transport, we understand that our customers are the lifeblood of our business. We deeply value their trust and loyalty, and as such, we go above and beyond to ensure their satisfaction. We firmly believe that providing a luxury service is not just a privilege, but a responsibility, and we take this duty seriously.<br /><br />

                                        One of our core principles is delivering an unforgettable chauffeur service to our clients. Whether you are traveling for business, leisure, or any special occasion, we are dedicated to making your journey comfortable, elegant, and truly memorable. Our team is committed to providing top-notch customer service that will leave a lasting impression.<br /><br />

                                        We understand that transportation needs can arise at any time, which is why we are available to serve you 24 hours a day, 7 days a week, and even on holidays. Whether you need a ride to the airport early in the morning or a late-night pickup after a social event, you can rely on us to be there promptly and ready to cater to your transportation needs.<br /><br />

                                        Our drivers are the epitome of professionalism. They are carefully selected for their exceptional skills, experience, and dedication to their craft. Our drivers not only possess excellent driving skills but also embody qualities of respect, courtesy, and a commitment to providing an exceptional customer experience.<br /><br />
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs;