import styles from '../styles/components/inputs.module.css';

interface InputProps{
    title: string,
    placeholder: string,
    Icon?: JSX.Element,
    mt?: number,
    type: string,
    is_phone_number?: boolean,
    setValue?: Function
}

const TextInputWithIcon = ({title, placeholder, Icon, mt, type, is_phone_number, setValue}: InputProps) => {
    return(
        <div style={{marginTop: mt ? mt : 0}}>
            <label style={{fontWeight: '500', color: '#414141'}}>{title}</label>
            <div className={styles.inputs}>
                {Icon}
                {is_phone_number && <span style={{marginLeft: 5}}>+1</span>}
                <input onChange={(e) => setValue ? setValue(e.target.value) : {}} type={type} placeholder={placeholder} />
            </div>
        </div>
    )
}
export default TextInputWithIcon;