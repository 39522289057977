import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import OurVehicles from './pages/OurVehicles';
import BookNow from './pages/BookNow';
import axios from 'axios';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import MyBookings from './pages/MyBookings';
import ThankYou from './pages/ThankYou';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/book-now',
    element: <BookNow />
  },
  {
    path: '/my-bookings',
    element: <MyBookings />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/thank-you',
    element: <ThankYou />
  },
  {
    path: '/our-vehicles',
    element: <OurVehicles />
  }
]);

const queryClient = new QueryClient()


function App() {

  let baseUrl = process.env.REACT_APP_BASE_URL;
  // axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
  axios.defaults.baseURL = baseUrl;

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
